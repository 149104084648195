import CurriculumConfirmationModal from "@/content-usage/ modals/CurriculumConfirmationModal"
import useTrackContentCompletion from "@/content-usage/buttons/useTrackContentCompletion"
import { ContentUsageMarkAsCompleteButtonFragment$key } from "@/content-usage/buttons/__generated__/ContentUsageMarkAsCompleteButtonFragment.graphql"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import { DiscoButton, DiscoTooltip } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ContentUsageMarkAsCompleteButtonProps extends TestIDProps {
  contentUsageKey: ContentUsageMarkAsCompleteButtonFragment$key
}

function ContentUsageMarkAsCompleteButton({
  contentUsageKey,
  testid,
}: ContentUsageMarkAsCompleteButtonProps) {
  const activeProduct = useActiveProduct()!
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const isProductMember = Boolean(activeProduct.viewerMembership)
  const productLabel = useLabel("experience")

  const contentUsage = useFragment<ContentUsageMarkAsCompleteButtonFragment$key>(
    graphql`
      fragment ContentUsageMarkAsCompleteButtonFragment on ContentUsage {
        id
        ...useTrackContentCompletionFragment
        confirmationModalContent {
          ...CurriculumConfirmationModalConfirmationModalFragment
        }
      }
    `,
    contentUsageKey
  )

  const completeContent = useTrackContentCompletion(contentUsage)
  return (
    <>
      <DiscoTooltip
        disabled={isProductMember}
        content={`Must register for the ${productLabel.singular} to mark this as complete`}
      >
        <DiscoButton
          testid={`${testid}.complete-item-button`}
          disabled={!isProductMember || isLoading}
          shouldDisplaySpinner={isLoading}
          onClick={
            contentUsage.confirmationModalContent ? () => setIsOpen(true) : handleComplete
          }
          rightIcon={"arrow-stem-right"}
        >
          {"Complete and Continue"}
        </DiscoButton>
      </DiscoTooltip>
      {contentUsage.confirmationModalContent && (
        <CurriculumConfirmationModal
          content={contentUsage.confirmationModalContent}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onConfirm={handleComplete}
          isLoading={isLoading}
        />
      )}
    </>
  )

  function handleComplete() {
    setIsLoading(true)
    completeContent().finally(() => setIsLoading(false))
  }
}
export default ContentUsageMarkAsCompleteButton
