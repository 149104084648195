/**
 * @generated SignedSource<<e46eb547888cb9563433d6761bd2c543>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentUsageUtils_useSubmissionDeadlineFragment$data = {
  readonly dueAt: string | null;
  readonly viewerSubmission: {
    readonly createdAt: string;
  } | null;
  readonly " $fragmentType": "ContentUsageUtils_useSubmissionDeadlineFragment";
};
export type ContentUsageUtils_useSubmissionDeadlineFragment$key = {
  readonly " $data"?: ContentUsageUtils_useSubmissionDeadlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_useSubmissionDeadlineFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageUtils_useSubmissionDeadlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentSubmission",
      "kind": "LinkedField",
      "name": "viewerSubmission",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "71d90b94b6af3dd3122bb8ae3d07e4a8";

export default node;
