import ShareContentUsageEmailModalBody from "@/content-usage/buttons/share/ShareContentUsageEmailModalBody"
import { useLabel } from "@/core/context/LabelsContext"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoModal } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
interface ShareContentUsageEmailModalProps extends TestIDProps {
  isOpen: boolean
  onClose: VoidFunction
  title: string
  contentUsageId: GlobalID
}

function ShareContentUsageEmailModal({
  isOpen,
  onClose,
  testid = "ShareContentUsageEmailModal",
  title,
  contentUsageId,
}: ShareContentUsageEmailModalProps) {
  const memberLabel = useLabel("product_member")
  return (
    <DiscoModal
      isOpen={isOpen}
      onClose={onClose}
      testid={testid}
      title={title}
      subtitle={`Sharing by email is a great way to encourage ${memberLabel.plural} to engage in your Community! 🚀`}
      modalContentLabel={title}
      width={"720px"}
      buttons
      fullWidthButtons
      body={
        <ShareContentUsageEmailModalBody
          onClose={onClose}
          contentUsageId={contentUsageId}
        />
      }
    />
  )
}

export default ShareContentUsageEmailModal
