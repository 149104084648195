import AdminContentListFilterTags from "@/admin/content-library/filters/AdminContentListFilterTags"
import AdminContentListToolbar, {
  AdminContentListToolbarState,
} from "@/admin/content-library/filters/AdminContentListToolbar"
import ExistingContentList, {
  ExistingContentListSkeleton,
  EXISTING_CONTENT_CONTENTS_PER_LOAD,
} from "@/content-usage/buttons/add-button/ExistingContentList"
import { AddContentUsageModalExistingContentTabQuery } from "@/content-usage/buttons/add-button/__generated__/AddContentUsageModalExistingContentTabQuery.graphql"
import {
  ContentType,
  ContentUsageInput,
} from "@/content-usage/__generated__/ContentModulesDragDropProvider_UpdateContentUsageMutation.graphql"
import { ContentUsageEntity } from "@/content-usage/__generated__/ContentUsageUtils_UseParentEntityContentUsageFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { DiscoSection } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

interface Props extends TestIDProps {
  typesToExclude: ContentType[]
  onSelect?: VoidFunction
  addingTo: ContentUsageEntity
  productId?: GlobalID | null
  contentUsageInput?: ContentUsageInput | null
  onContentSelected?: (contentId: GlobalID) => void
  disabled?: boolean
}

function AddContentUsageModalExistingContentTab({
  testid = "AddContentUsageModalExistingContentTab",
  typesToExclude = [],
  productId,
  contentUsageInput,
  onContentSelected,
  onSelect,
  addingTo,
  disabled,
}: Props) {
  const [toolbarState, setToolbarState] = useState<AdminContentListToolbarState>({
    search: "",
    contentLabelId: null,
    selectedProduct: null,
    selectedCollection: null,
    sort: {
      id: "none",
      order: { field: "created_at" },
      title: "Sort",
    },
    direction: "DESC",
  })
  const isMobile = useIsMobile()
  const classes = useStyles()

  const activeOrganization = useActiveOrganization()!
  const { organization } = useLazyLoadQuery<AddContentUsageModalExistingContentTabQuery>(
    graphql`
      query AddContentUsageModalExistingContentTabQuery(
        $id: ID!
        $first: Int!
        $after: String
        $search: String
        $contentLabelIds: [ID!]
        $excludedTypes: [ContentType!]
        $productIds: [ID!]
        $collectionIds: [ID!]
      ) {
        organization: node(id: $id) {
          ... on Organization {
            ...ExistingContentList_PaginationFragment
              @arguments(
                first: $first
                after: $after
                search: $search
                contentLabelIds: $contentLabelIds
                excludedTypes: $excludedTypes
                productIds: $productIds
                collectionIds: $collectionIds
              )
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      first: EXISTING_CONTENT_CONTENTS_PER_LOAD,
      search: toolbarState.search,
      contentLabelIds: toolbarState.contentLabelId?.value
        ? [toolbarState.contentLabelId.value]
        : undefined,
      productIds: toolbarState.selectedProduct?.value
        ? [toolbarState.selectedProduct.value]
        : undefined,
      collectionIds: toolbarState.selectedCollection?.value
        ? [toolbarState.selectedCollection.value]
        : undefined,
      excludedTypes: typesToExclude,
    },
    {
      fetchPolicy: "store-and-network",
    }
  )

  if (!organization) return null
  return (
    <DiscoSection groovyDepths={"boxShadow"} padding={isMobile ? 1 : 2.5} marginTop={2}>
      <div className={classes.toolbar}>
        <AdminContentListToolbar
          testid={testid}
          toolbarState={toolbarState}
          setToolbarState={setToolbarState}
        />
      </div>

      <AdminContentListFilterTags
        filterState={toolbarState}
        setFilterState={(newFilterState) =>
          setToolbarState({ ...toolbarState, ...newFilterState })
        }
      />

      <ScrollShadowContainer classes={{ scrollContainer: classes.listContainer }}>
        <ExistingContentList
          testid={testid}
          productId={productId}
          contentUsageInput={contentUsageInput}
          onContentSelected={onContentSelected}
          onSelect={onSelect}
          addingTo={addingTo}
          organizationKey={organization}
          disabled={disabled}
        />
      </ScrollShadowContainer>
    </DiscoSection>
  )
}

const useStyles = makeUseStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  listContainer: {
    maxHeight: "380px",
    overflowY: "auto",
  },
}))

function AddContentUsageModalExistingContentTabSkeleton() {
  const isMobile = useIsMobile()
  return (
    <DiscoSection groovyDepths={"boxShadow"} padding={isMobile ? 1 : 2.5}>
      <ExistingContentListSkeleton />
    </DiscoSection>
  )
}

export default Relay.withSkeleton({
  component: AddContentUsageModalExistingContentTab,
  skeleton: AddContentUsageModalExistingContentTabSkeleton,
})
