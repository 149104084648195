/**
 * @generated SignedSource<<96b183a5f16c8e28a4ffcd373729bae6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
export type AddContentUsageModalExistingContentTabQuery$variables = {
  id: string;
  first: number;
  after?: string | null;
  search?: string | null;
  contentLabelIds?: ReadonlyArray<string> | null;
  excludedTypes?: ReadonlyArray<ContentType> | null;
  productIds?: ReadonlyArray<string> | null;
  collectionIds?: ReadonlyArray<string> | null;
};
export type AddContentUsageModalExistingContentTabQuery$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"ExistingContentList_PaginationFragment">;
  } | null;
};
export type AddContentUsageModalExistingContentTabQuery = {
  variables: AddContentUsageModalExistingContentTabQuery$variables;
  response: AddContentUsageModalExistingContentTabQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "collectionIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentLabelIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludedTypes"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productIds"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "collectionIds",
    "variableName": "collectionIds"
  },
  {
    "kind": "Variable",
    "name": "contentLabelIds",
    "variableName": "contentLabelIds"
  },
  {
    "kind": "Variable",
    "name": "excludedTypes",
    "variableName": "excludedTypes"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "productIds",
    "variableName": "productIds"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddContentUsageModalExistingContentTabQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v9/*: any*/),
                "kind": "FragmentSpread",
                "name": "ExistingContentList_PaginationFragment"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddContentUsageModalExistingContentTabQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "ContentNodeConnection",
                "kind": "LinkedField",
                "name": "adminLibraryContents",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Content",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "label",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "creationDatetime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "coverVideo",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnailUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "showCoverPlayIcon",
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfoObjectType",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "filters": [
                  "search",
                  "contentLabelIds",
                  "excludedTypes",
                  "productIds",
                  "collectionIds"
                ],
                "handle": "connection",
                "key": "ExistingContentList__adminLibraryContents",
                "kind": "LinkedHandle",
                "name": "adminLibraryContents"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "03dd61056ce8c17a0fc53dd5ba2bf549",
    "id": null,
    "metadata": {},
    "name": "AddContentUsageModalExistingContentTabQuery",
    "operationKind": "query",
    "text": "query AddContentUsageModalExistingContentTabQuery(\n  $id: ID!\n  $first: Int!\n  $after: String\n  $search: String\n  $contentLabelIds: [ID!]\n  $excludedTypes: [ContentType!]\n  $productIds: [ID!]\n  $collectionIds: [ID!]\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      ...ExistingContentList_PaginationFragment_47Lvr\n    }\n    id\n  }\n}\n\nfragment AddContentUsageContentListItemFragment on Content {\n  id\n  type\n  name\n  ...ContentThumbnailWithDetails_ContentFragment\n}\n\nfragment ContentThumbnailWithDetails_ContentFragment on Content {\n  id\n  type\n  label\n  name\n  creationDatetime\n  ...ContentThumbnail_ContentFragment\n}\n\nfragment ContentThumbnail_ContentFragment on Content {\n  id\n  type\n  coverVideo\n  thumbnailUrl\n  showCoverPlayIcon\n}\n\nfragment ExistingContentList_PaginationFragment_47Lvr on Organization {\n  adminLibraryContents(first: $first, after: $after, search: $search, contentLabelIds: $contentLabelIds, excludedTypes: $excludedTypes, productIds: $productIds, collectionIds: $collectionIds) {\n    edges {\n      node {\n        id\n        ...AddContentUsageContentListItemFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "79ee108a762c60ff763fe2ee632e926f";

export default node;
