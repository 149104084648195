import { ContentUsageAssociateEventsButtonMutation } from "@/content-usage/buttons/events/__generated__/ContentUsageAssociateEventsButtonMutation.graphql"
import { ContentUsageFormEventsFieldQuery } from "@/content-usage/buttons/events/__generated__/ContentUsageFormEventsFieldQuery.graphql"
import { ContentModuleFormState } from "@/content-usage/modules/actions/ContentModuleForm"
import {
  ContentModuleForm_UpdateContentUsageMutation,
  UpdateContentUsageInput,
} from "@/content-usage/modules/actions/__generated__/ContentModuleForm_UpdateContentUsageMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import FormStore from "@/core/form/store/FormStore"
import OccurrenceNameAndTime, {
  OccurrenceNameAndTimeSkeleton,
} from "@/product/course/event/components/OccurrenceNameAndTime"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DaySquare, { DaySquareSkeleton } from "@components/date/day-square/DaySquare"
import {
  DiscoIcon,
  DiscoIconButton,
  DiscoInputSkeleton,
  DiscoSection,
  DiscoText,
} from "@disco-ui"
import DiscoMultiSelect, {
  DiscoMultiSelectOption,
} from "@disco-ui/select/DiscoMultiSelect"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { isBefore } from "date-fns"
import { IObservableArray } from "mobx"
import { observer } from "mobx-react-lite"
import { ReactNode, useMemo } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

type Props = {
  form:
    | FormStore<ContentModuleFormState, ContentModuleForm_UpdateContentUsageMutation>
    | FormStore<UpdateContentUsageInput, ContentUsageAssociateEventsButtonMutation>
  showEventsContainer?: boolean
}

function ContentUsageFormEventsField(props: Props) {
  const { form, showEventsContainer = false } = props
  const classes = useStyles()
  const activeProduct = useActiveProduct()
  const activeOrganization = useActiveOrganization()
  const userTimezone = useUserTimezone()

  const { organization } = useLazyLoadQuery<ContentUsageFormEventsFieldQuery>(
    graphql`
      query ContentUsageFormEventsFieldQuery($productId: ID!, $organizationId: ID!) {
        organization: node(id: $organizationId) {
          ... on Organization {
            occurrences(
              status: published
              visibility: [cohort, public]
              includedProductIds: [$productId]
            ) {
              edges {
                node {
                  id
                  status
                  datetimeRange
                  content {
                    name
                  }
                  ...OccurrenceNameAndTimeFragment
                }
              }
            }
          }
        }
      }
    `,
    {
      productId: activeProduct?.id || "",
      organizationId: activeOrganization?.id || "",
    }
  )

  const occurrences = Relay.connectionToArray(organization?.occurrences)
  // Sort occurrences by date in order of upcoming->asc, then past->desc, for select dropdown
  const occurrencesCustomSorted = useMemo(() => {
    const upcoming = [],
      past = [],
      now = new Date()
    for (const occurrence of occurrences) {
      if (isBefore(new Date(occurrence.datetimeRange[1]), now)) {
        past.push(occurrence)
      } else {
        upcoming.unshift(occurrence)
      }
    }
    return upcoming.concat(past)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.occurrences])

  const options = useMemo(
    () =>
      occurrencesCustomSorted
        .filter(
          (occurrence) =>
            !form.state.contentUsageInput?.occurrences?.includes(occurrence.id)
        )
        .map((occurrence) => ({
          title: occurrence.content?.name || "",
          value: occurrence.id,
          searchable: [
            occurrence.content?.name,
            formatDateWithOptions({
              timeZone: userTimezone,
              format: DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT,
            })(new Date(occurrence.datetimeRange[0])),
          ],
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      occurrencesCustomSorted,
      userTimezone,
      form.state.contentUsageInput?.occurrences?.length,
    ]
  )
  const selectedOccurrences = occurrencesCustomSorted.filter((occurrence) =>
    form.state.contentUsageInput?.occurrences?.includes(occurrence.id)
  )

  return (
    <>
      <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={1}>
        {"Only published events may be linked to a Module."}
      </DiscoText>
      <DiscoMultiSelect
        testid={"ContentUsageFormEventsField.select"}
        options={options}
        values={[]}
        onChange={handleSelect}
        renderOption={(option) => (
          <OccurrenceNameAndTime
            classes={{ root: classes.selectOption }}
            occurrenceKey={occurrences.find((o) => o.id === option.value)!}
            variant={"compact"}
            testid={`ContentUsageFormEventsField.option`}
          />
        )}
        placeholder={"Type to search events"}
        filterOptions={{ maxVisible: 100 }}
        groupBy={getOptionGroup}
      />
      {renderEventsContainer(
        selectedOccurrences?.map((occurrence, i) => (
          <div
            key={occurrence.id}
            className={classes.occurrence}
            data-testid={`ContentUsageFormEventsField.occurrence-${i}`}
          >
            <DaySquare
              testid={"list-event-day"}
              date={new Date(occurrence.datetimeRange[0])}
              customClassName={classes.daySquare}
            />
            <OccurrenceNameAndTime occurrenceKey={occurrence} variant={"compact"} />
            <DiscoIconButton
              onClick={() =>
                (form.state.contentUsageInput?.occurrences as IObservableArray)?.splice(
                  i,
                  1
                )
              }
              testid={`ContentUsageFormEventsField.occurrence-${i}.remove`}
            >
              <DiscoIcon icon={"close"} />
            </DiscoIconButton>
          </div>
        ))
      )}
    </>
  )

  function handleSelect(values: string[]) {
    if (!values[0]) return
    ;(form.state.contentUsageInput?.occurrences as IObservableArray)?.push(values[0])
  }

  function renderEventsContainer(children: ReactNode) {
    return showEventsContainer && selectedOccurrences?.length ? (
      <DiscoSection marginTop={1.5} padding={2} groovyDepths={"insideCard"}>
        {children}
      </DiscoSection>
    ) : (
      children
    )
  }

  function getOptionGroup(option: DiscoMultiSelectOption) {
    const occurrence = occurrences.find((o) => o.id === option.value)!
    return isBefore(new Date(occurrence.datetimeRange[1]), new Date())
      ? "Past"
      : "Upcoming"
  }
}

const useStyles = makeUseStyles((theme) => ({
  occurrence: {
    marginTop: theme.spacing(1.5),
    "&:first-child": {
      marginTop: 0,
    },
    display: "flex",
    width: "100%",
    alignItems: "center",
    minWidth: 0,
  },
  daySquare: {
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  selectOption: {
    marginLeft: `0 !important`,
    marginBottom: theme.spacing(1),
  },
}))

function ContentUsageFormEventsFieldSkeleton() {
  const classes = useStyles()
  return (
    <>
      <DiscoInputSkeleton />
      <div className={classes.occurrence}>
        <DaySquareSkeleton customClassName={classes.daySquare} />
        <OccurrenceNameAndTimeSkeleton />
      </div>
    </>
  )
}

export default Relay.withSkeleton({
  component: observer(ContentUsageFormEventsField),
  skeleton: ContentUsageFormEventsFieldSkeleton,
})
