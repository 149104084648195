import { ContentModuleUtils_useCurriculumModuleIdsFragment$key } from "@/content-usage/__generated__/ContentModuleUtils_useCurriculumModuleIdsFragment.graphql"
import { ContentModuleUtils_useOnCurriculumModuleCompletedFragment$key } from "@/content-usage/__generated__/ContentModuleUtils_useOnCurriculumModuleCompletedFragment.graphql"
import { ContentUsageEntity } from "@/content-usage/buttons/__generated__/DeleteContentUsageButtonFragment.graphql"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions, getTimeDifferenceAsText } from "@utils/time/timeUtils"
import { isAfter } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { useEffect, useRef } from "react"
import { useFragment } from "react-relay"
import ConnectionHandlerPlus from "relay-connection-handler-plus"
import { RecordSourceProxy, graphql } from "relay-runtime"

export namespace ContentModuleUtils {
  export function getContentModuleLabel(parentEntity: ContentUsageEntity) {
    switch (parentEntity) {
      case "collection":
        return "Folder"
    }
    return "Module"
  }

  export function isReleased(module?: { releasedAt: string | null } | null): boolean {
    if (!module?.releasedAt) return true
    return isAfter(new Date(), new Date(module.releasedAt))
  }

  export function useFormattedReleaseDate(opts: {
    releasedAt?: string | null
    timeZone: string
    copy?: {
      releasedAt?: string
      releaseDate?: string
      variant?: "ago" | "on"
      dateFormat?: string
    }
  }) {
    const { releasedAt, timeZone, copy } = opts

    const formatDate = formatDateWithOptions({
      timeZone,
      format:
        copy?.dateFormat ??
        DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT,
      shouldShiftDateToCompensateForTimezone: false,
    })

    const timeSinceReleased = getTimeDifferenceAsText(
      utcToZonedTime(releasedAt ? new Date(releasedAt) : new Date(), timeZone)
    )

    if (!releasedAt) return null

    if (isReleased({ releasedAt })) {
      const releaseDate = formatDate(new Date(releasedAt))
      if (copy?.releasedAt) return `${copy.releasedAt} ${timeSinceReleased}`
      if (copy?.variant === "on") return `Released on ${releaseDate}`
      return `Released ${timeSinceReleased}`
    }
    return `Release date: ${formatDate(new Date(releasedAt))}`
  }

  /** Get a list of all curriculum module ids */
  export function useCurriculumModuleIds({
    releasedOnly = false,
  }: { releasedOnly?: boolean } = {}) {
    const curriculum = useFragment<ContentModuleUtils_useCurriculumModuleIdsFragment$key>(
      graphql`
        fragment ContentModuleUtils_useCurriculumModuleIdsFragment on Curriculum {
          id
          modules {
            edges {
              node {
                id
                isReleased
                contentId
              }
            }
          }
        }
      `,
      useActiveProduct()?.curriculum || null
    )

    const modules = Relay.connectionToArray(curriculum?.modules)

    if (releasedOnly)
      return modules
        .filter((m) => m.isReleased)
        .map((m) => ({ moduleId: m.contentId, usageId: m.id }))
    return modules.map((m) => ({ moduleId: m.contentId, usageId: m.id }))
  }

  export function invalidateCollectionFolderConnections(
    store: RecordSourceProxy,
    entity: ContentUsageEntity | null | undefined,
    entityId: string | null | undefined
  ) {
    if (entity !== "content") return

    if (!entityId) return

    const contentRecord = store.get(entityId)
    if (!contentRecord) return
    ConnectionHandlerPlus.getConnections(
      contentRecord,
      "CollectionFolderDashboardBlockContent_children"
    ).forEach((connection) => connection.invalidateRecord())
  }

  /** Execute a callback effect whenever a curriculum module is completed */
  export function useOnCurriculumModuleCompleted(
    onModuleCompleted: (moduleId: GlobalID) => void
  ) {
    const curriculum =
      useFragment<ContentModuleUtils_useOnCurriculumModuleCompletedFragment$key>(
        graphql`
          fragment ContentModuleUtils_useOnCurriculumModuleCompletedFragment on Curriculum {
            id
            modules {
              edges {
                node {
                  id
                  viewerHasCompleted
                }
              }
            }
          }
        `,
        useActiveProduct()?.curriculum || null
      )

    const completedModuleIds = Relay.connectionToArray(curriculum?.modules)
      .filter((module) => module.viewerHasCompleted)
      .map((module) => module.id)

    const trackedCompletions = useRef(new Set<GlobalID>(completedModuleIds))

    // When changing products, reset the tracked completions to
    // include the completed sections of the new product.
    useEffect(() => {
      trackedCompletions.current = new Set(completedModuleIds)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curriculum?.id])

    useEffect(() => {
      completedModuleIds.forEach((id) => {
        if (trackedCompletions.current.has(id)) return
        trackedCompletions.current.add(id)
        onModuleCompleted(id)
      })
    }, [onModuleCompleted, completedModuleIds])
  }

  export function encodeModuleDroppableId(module: {
    id: string
    content: { id: string }
  }) {
    return `${module.id}:${module.content.id}`
  }

  export function decodeModuleDroppableId(encoded: string): {
    moduleId: string
    contentId: string
  } {
    const [moduleId, contentId] = encoded.split(":")
    return { moduleId, contentId }
  }

  export function insertUsageIntoModuleGridConnection(opts: {
    store: RecordSourceProxy
    moduleId: GlobalID
    contentUsageId: GlobalID
    ordering?: number | null
  }) {
    const { store, moduleId, contentUsageId, ordering } = opts

    const moduleRecord = store.get(moduleId)
    if (!moduleRecord) return
    ConnectionHandlerPlus.getConnections(
      moduleRecord,
      "ContentUsageGrid__children"
    ).forEach((connection) => {
      Relay.insertNodeIntoPaginatedConnection(
        store,
        connection,
        store.get(contentUsageId)!,
        ordering
      )
    })
  }
}

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment ContentModuleUtils_RefreshContentModulesFragment on ContentUsage
  @argumentDefinitions(contentLabelIds: { type: "[ID!]" }) {
    id
    curriculum {
      id
      ...ContentModuleUtils_RefreshCurriculumFragment
        @arguments(contentLabelIds: $contentLabelIds)
    }
    collection {
      id
      ...ContentModuleUtils_RefreshCollectionFragment
    }
  }
`

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment ContentModuleUtils_RefreshCurriculumFragment on Curriculum
  @argumentDefinitions(contentLabelIds: { type: "[ID!]" }) {
    id
    modules {
      totalCount
      edges {
        node {
          id
          ordering
          ...ContentModuleListItemFragment @arguments(contentLabelIds: $contentLabelIds)
        }
      }
    }
  }
`

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment ContentModuleUtils_RefreshCollectionFragment on Collection {
    id
    modules {
      totalCount
      edges {
        node {
          id
          ordering
          ...ContentModuleGridItemFragment
          ...ContentModuleNavigationSectionFragment
        }
      }
    }
  }
`
