import { ContentUsageInput } from "@/content-usage/__generated__/ContentModulesDragDropProvider_UpdateContentUsageMutation.graphql"
import { ContentUsageUtils_UseParentEntityContentUsageFragment$key } from "@/content-usage/__generated__/ContentUsageUtils_UseParentEntityContentUsageFragment.graphql"
import {
  ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment$data,
  ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment$key,
} from "@/content-usage/__generated__/ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment.graphql"
import { ContentUsageUtils_useContentUsagePathContentUsageFragment$key } from "@/content-usage/__generated__/ContentUsageUtils_useContentUsagePathContentUsageFragment.graphql"
import { ContentUsageUtils_useNavigateToNextContentUsageFragment$key } from "@/content-usage/__generated__/ContentUsageUtils_useNavigateToNextContentUsageFragment.graphql"
import { ContentUsageUtils_useSubmissionDeadlineFragment$key } from "@/content-usage/__generated__/ContentUsageUtils_useSubmissionDeadlineFragment.graphql"
import { ContentType } from "@/content-usage/buttons/__generated__/DeleteContentUsageButtonFragment.graphql"
import {
  InlineContentDrawerFormState,
  InlineContentDrawerMode,
} from "@/content-usage/drawer/InlineContentDrawerTemplate"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import FormStore from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import Relay from "@/relay/relayUtils"
import { DiscoIcon } from "@disco-ui"
import { QueryParamAction } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatInTimeZone } from "date-fns-tz"
import { useCallback } from "react"
import { useFragment } from "react-relay"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"

export namespace ContentUsageUtils {
  /**
   * Returns the parent entity of a content usage, if the usage is in a module returns the module's parent entity
   */
  export function useParentEntity(
    contentUsageKey: ContentUsageUtils_UseParentEntityContentUsageFragment$key
  ) {
    const contentUsage =
      useFragment<ContentUsageUtils_UseParentEntityContentUsageFragment$key>(
        graphql`
          fragment ContentUsageUtils_UseParentEntityContentUsageFragment on ContentUsage {
            id
            entity
            module {
              isCurriculumModule
              isCollectionModule
            }
            productApp {
              kind
            }
          }
        `,
        contentUsageKey
      )

    const isModule =
      contentUsage.entity === "curriculum" || contentUsage.entity === "collection"

    if (contentUsage.module) {
      if (contentUsage.module.isCurriculumModule)
        return { parentEntity: "curriculum", isModule }
      if (contentUsage.module.isCollectionModule)
        return { parentEntity: "collection", isModule }
    }

    return { parentEntity: contentUsage.entity, isModule }
  }

  export function getContentEmptyThumbnail(content: { type: ContentType }) {
    switch (content.type) {
      case "assignment":
        return <DiscoIcon icon={"file-dock"} />
      case "custom":
        return <DiscoIcon icon={"book-check"} />
      case "survey":
        return <DiscoIcon icon={"file-check"} />
      default:
        return <DiscoIcon icon={"book-open"} />
    }
  }

  export function getFormattedDueDate(
    dueDate: Date,
    timezone: string,
    joiner: "at" | "@"
  ) {
    return `${formatInTimeZone(
      dueDate,
      timezone,
      DATE_FORMAT.DEFAULT_FULL_MONTH
    )} ${joiner} ${formatInTimeZone(dueDate, timezone, DATE_FORMAT.TIME_WITH_ZONE)}`
  }

  export function useNavigateToNextContentUsage(
    contentUsageKey: ContentUsageUtils_useNavigateToNextContentUsageFragment$key
  ) {
    const contentUsageDrawer = useContentUsageDrawer()

    const contentUsage =
      useFragment<ContentUsageUtils_useNavigateToNextContentUsageFragment$key>(
        graphql`
          fragment ContentUsageUtils_useNavigateToNextContentUsageFragment on ContentUsage {
            id
            module {
              children {
                edges {
                  node {
                    id
                    content {
                      id
                      type
                      name
                      systemTaskKind
                      ...ContentThumbnailWithDetails_ContentFragment
                    }
                  }
                }
              }
            }
          }
        `,
        contentUsageKey
      )

    const children = Relay.connectionToArray(contentUsage.module?.children)

    return useCallback(
      (action?: QueryParamAction) => {
        const currentIndex = children.findIndex((child) => child.id === contentUsage.id)
        const nextContentUsage = children[currentIndex + 1] || children[currentIndex - 1]
        if (nextContentUsage) {
          contentUsageDrawer.open({ drawerContentUsageId: nextContentUsage.id }, action)
          return
        }
        contentUsageDrawer.close()
      },
      [contentUsageDrawer, contentUsage.id, children]
    )
  }

  export function useContentUsageLocationLabel(
    contentUsageKey?: ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment$key | null
  ) {
    const contentUsage =
      useFragment<ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment$key>(
        graphql`
          fragment ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment on ContentUsage {
            id
            entity
            entityId
            product {
              name
            }
            productApp {
              kind
              customAppTitle
            }
            collection {
              app {
                customAppTitle
              }
            }
            module {
              name
            }
          }
        `,
        contentUsageKey ?? null
      )

    return contentUsageLocationLabel(contentUsage)
  }

  type ContentUsageLocationLabelData = Partial<
    Omit<
      ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment$data,
      " $fragmentType"
    >
  > | null
  export function contentUsageLocationLabel(contentUsage: ContentUsageLocationLabelData) {
    if (!contentUsage) return ""

    if (contentUsage.product) {
      const entity = contentUsage.module?.name ?? contentUsage.productApp?.customAppTitle
      return entity
        ? `${contentUsage.product.name} • ${entity}`
        : contentUsage.product.name
    }

    // Community Collection content (folders, modules)
    if (contentUsage.collection)
      return contentUsage.collection.app.customAppTitle || "Collection"

    // Community Folder content (content of a folder)
    if (contentUsage.module) return contentUsage.module.name || "Module"

    // Community Apps
    if (contentUsage.productApp) return contentUsage.productApp.customAppTitle || "Page"

    // Community share link usage
    if (contentUsage.entity === "organization") return "Community"

    return ""
  }

  export function useContentUsagePath(
    contentUsageKey?: ContentUsageUtils_useContentUsagePathContentUsageFragment$key | null
  ) {
    const contentUsage =
      useFragment<ContentUsageUtils_useContentUsagePathContentUsageFragment$key>(
        graphql`
          fragment ContentUsageUtils_useContentUsagePathContentUsageFragment on ContentUsage {
            id
            entity
            entityId
            product {
              slug
            }
            productApp {
              kind
            }
            module {
              usages {
                edges {
                  node {
                    id
                    entity
                    entityId
                  }
                }
              }
            }
          }
        `,
        contentUsageKey ?? null
      )

    // If no content usage return community home
    if (!contentUsage) return ROUTE_NAMES.COMMUNITY.HOME.ROOT

    const sourceUsage = contentUsage.module
      ? Relay.connectionToArray(contentUsage.module.usages)[0]
      : contentUsage

    // If content usage is not in a product check community collection otherwise go community home
    if (!contentUsage.product) {
      switch (sourceUsage.entity) {
        case "collection":
          return generatePath(ROUTE_NAMES.COMMUNITY.COLLECTION.DETAIL, {
            collectionId: sourceUsage.entityId,
          })
        case "organization":
          // For organization share links, just open the drawer on current path
          return location.pathname
        default:
          return ROUTE_NAMES.COMMUNITY.HOME.ROOT
      }
    }

    switch (sourceUsage.entity) {
      case "curriculum":
        return generatePath(ROUTE_NAMES.PRODUCT.CURRICULUM.OVERVIEW, {
          productSlug: contentUsage.product.slug,
        })
      case "collection":
        return generatePath(ROUTE_NAMES.PRODUCT.COLLECTION.DETAIL, {
          productSlug: contentUsage.product.slug,
          collectionId: sourceUsage.entityId,
        })
      default:
        // ie. If content usage is content app within a product
        return generatePath(ROUTE_NAMES.PRODUCT.ROOT, {
          productSlug: contentUsage.product.slug,
        })
    }
  }

  export function useSubmissionDeadline(args: {
    mode: InlineContentDrawerMode
    contentForm: FormStore<InlineContentDrawerFormState> | null
    contentUsageKey: ContentUsageUtils_useSubmissionDeadlineFragment$key | null
  }): {
    dueAt: Date | null
    submittedAt: Date | null
    isLateSubmission: boolean
  } {
    const { mode, contentForm, contentUsageKey } = args
    const contentUsage = useFragment<ContentUsageUtils_useSubmissionDeadlineFragment$key>(
      graphql`
        fragment ContentUsageUtils_useSubmissionDeadlineFragment on ContentUsage {
          dueAt
          viewerSubmission {
            createdAt
          }
        }
      `,
      contentUsageKey
    )

    const dueAtValue =
      mode !== "read" && contentForm
        ? contentForm.state.contentUsageInput?.dueAt
        : contentUsage?.dueAt
    const dueAt = dueAtValue ? new Date(dueAtValue) : null
    const submittedAt =
      mode === "read" && contentUsage?.viewerSubmission?.createdAt
        ? new Date(contentUsage.viewerSubmission.createdAt)
        : null
    const isLateSubmission = Boolean(
      dueAt && (submittedAt ? submittedAt > dueAt : new Date() > dueAt)
    )

    return { dueAt, submittedAt, isLateSubmission }
  }

  export function getDefaultSettings(type: ContentType): ContentUsageInput {
    switch (type) {
      case "assignment":
        return {
          submissionMethod: "submission",
          submissionVisibility: "cohort",
          submissionFormat: {
            text: { enabled: true, required: false },
            file: { enabled: true, required: false },
            link: { enabled: false, required: false },
          },
        }
      default:
        return {}
    }
  }
}

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment ContentUsageUtils_ContentCompletionFragment on ContentUsage {
    id
    viewerHasCompleted
    dependents {
      edges {
        node {
          id
          isLocked
        }
      }
    }
    module {
      id
      usages {
        edges {
          node {
            id
            dependents {
              edges {
                node {
                  id
                  isLocked
                  content {
                    children {
                      edges {
                        node {
                          id
                          isLocked
                        }
                      }
                    }
                  }
                }
              }
            }
            viewerHasCompleted
            completedProductMemberships {
              totalCount
              edges {
                node {
                  id
                  member {
                    id
                    first_name: firstName
                    last_name: lastName
                    avatar
                  }
                }
              }
            }
          }
        }
      }
      viewerChildContentCompletions {
        totalCount
      }
    }
    completedProductMemberships {
      totalCount
    }
  }
`
