import ShareContentUsageEmailModal from "@/content-usage/buttons/share/ShareContentUsageEmailModal"
import ShareContentUsageLinkModal from "@/content-usage/buttons/share/ShareContentUsageLinkModal"
import { ShareContentUsageUtils } from "@/content-usage/buttons/share/ShareContentUsageUtils"
import { ShareContentUsageButtonFragment$key } from "@/content-usage/buttons/share/__generated__/ShareContentUsageButtonFragment.graphql"
import { useContentUsageDrawerContext } from "@/content-usage/drawer/ContentUsageDrawerContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { LocationState } from "@/core/route/util/routeUtils"
import PublishExperienceButton from "@/product/buttons/PublishProduct/PublishExperienceButton"
import { DiscoButton, DiscoDivider, DiscoDropdown, DiscoLink } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { MouseEvent, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { useHistory, useLocation } from "react-router-dom"

interface ShareContentUsageButtonProps extends TestIDProps {
  contentUsageKey: ShareContentUsageButtonFragment$key
  children?: OverridableDiscoButtonChildren
  enableOpenOnCreation?: boolean
  classes?: ClassNameMap<"button">
}

type ShareMethod = "email" | "link"

function ShareContentUsageButton({
  contentUsageKey,
  testid = "ShareContentUsageButton",
  children = "Share",
  enableOpenOnCreation = false,
  classes,
}: ShareContentUsageButtonProps) {
  const activeProduct = useActiveProduct()
  const memberLabel = useLabel("product_member")
  const experienceDrawer = useGlobalDrawer("experienceSettings")
  const history = useHistory<LocationState>()
  const location = useLocation<LocationState>()
  const copyToClipboard = useCopyToClipboard()

  const contentUsage = useFragment<ShareContentUsageButtonFragment$key>(
    graphql`
      fragment ShareContentUsageButtonFragment on ContentUsage {
        id
        entity
        module {
          id
        }
        content {
          label
        }
        ...ShareContentUsageUtils_useShareUrlFragment
      }
    `,
    contentUsageKey
  )
  const checkNoShareReason = ShareContentUsageUtils.useCheckNotShareable()
  const noShareReason = checkNoShareReason({
    usageEntity: contentUsage.entity,
    curriculumModuleId: contentUsage.module?.id,
  })
  const shareUrl = ShareContentUsageUtils.useShareUrl(contentUsage)

  // exists when the modal should be opened on item creation
  const openOnCreation =
    location.state?.shareContentUsageId === contentUsage.id &&
    // Ignore enableOpenOnCreation setting for organization usages created by surveys
    (enableOpenOnCreation || contentUsage.entity === "organization")
  const { isOpen, onClose, onOpen } = useDisclosure(openOnCreation)
  const [shareMethod, setShareMethod] = useState<ShareMethod | null>(
    getDefaultShareMethod()
  )

  // Sharing currently only available for curriculum and share link
  const { isCurriculum, isShareLink } = useContentUsageDrawerContext()
  if (!isCurriculum && !isShareLink) return null

  const baseWarningProps = {
    isOpen,
    onClose,
    variant: "warning" as const,
    testid: `${testid}.cannot-share-warning-modal`,
    title: "Cannot share content",
    modalContentLabel: "Cannot share content",
  }

  return (
    <>
      <DiscoDropdown
        testid={`${testid}.dropdown`}
        menuButton={({ onClick, className }) => (
          <OverridableDiscoButton
            testid={testid}
            onClick={(e) => {
              if (noShareReason) return onOpen()
              onClick(e as MouseEvent<HTMLButtonElement>)
            }}
            className={classNames(className, classes?.button)}
          >
            {children}
          </OverridableDiscoButton>
        )}
      >
        <DiscoDropdownItem
          testid={`${testid}.link`}
          icon={"link"}
          title={"Copy Link"}
          onClick={() => handleShareOpen("link")}
        />
        {contentUsage.entity === "content" && (
          <>
            <DiscoDivider />
            <DiscoDropdownItem
              testid={`${testid}.email`}
              icon={"email"}
              title={"Send as email"}
              onClick={() => handleShareOpen("email")}
            />
          </>
        )}
      </DiscoDropdown>

      {renderNoShareModal()}

      {isOpen && shareMethod === "email" && (
        <ShareContentUsageEmailModal
          isOpen={isOpen}
          onClose={handleClose}
          contentUsageId={contentUsage.id}
          title={`Share ${contentUsage.content.label}`}
        />
      )}

      {isOpen && shareMethod === "link" && (
        <ShareContentUsageLinkModal
          isOpen={isOpen}
          onClose={handleClose}
          contentUsageId={contentUsage.id}
          title={`Share ${contentUsage.content.label}`}
        />
      )}
    </>
  )

  function renderNoShareModal() {
    if (!isOpen) return null

    switch (noShareReason) {
      case "draft_product":
        return (
          <DiscoWarningModal
            {...baseWarningProps}
            description={`This ${activeProduct?.typeLabel} is currently in draft mode and not visible to ${memberLabel.plural}. Publish the ${activeProduct?.typeLabel} to share this content.`}
            cancelButtonText={"Close"}
            customConfirmationButton={
              <PublishExperienceButton
                width={"100%"}
                skipConfirmation
                onPublish={onClose}
              >
                {`Publish ${activeProduct?.typeLabel}`}
              </PublishExperienceButton>
            }
          />
        )
      case "waiting_room":
        return (
          <DiscoWarningModal
            {...baseWarningProps}
            description={
              <>
                {`${memberLabel.plural} can only see the content when the ${activeProduct?.typeLabel} starts or early access is enabled. Visit the `}
                <DiscoLink onClick={handleOpenExperienceSettings}>
                  {`${activeProduct?.typeLabel} Settings`}
                </DiscoLink>
                {` if you want to share this content.`}
              </>
            }
            cancelButtonText={null}
            customConfirmationButton={
              <DiscoButton onClick={baseWarningProps.onClose} width={"100%"}>
                {"Got it!"}
              </DiscoButton>
            }
          />
        )
      case "unreleased_module":
        return (
          <DiscoWarningModal
            {...baseWarningProps}
            description={`This content is in an unreleased module. To share, move it to a section already visible to ${memberLabel.plural} or edit this section to be released.`}
            cancelButtonText={null}
            customConfirmationButton={
              <DiscoButton onClick={baseWarningProps.onClose} width={"100%"}>
                {"Got it!"}
              </DiscoButton>
            }
          />
        )
      default:
        return null
    }
  }

  function handleClose() {
    // remove the id from state so the modal won't be reopened on refresh
    if (openOnCreation)
      history.replace({
        ...location,
        state: { ...location.state, shareContentUsageId: undefined },
      })
    onClose()
    setShareMethod(null)
  }

  function handleOpenExperienceSettings() {
    onClose()
    experienceDrawer.open({
      drawerExperienceId: activeProduct?.id,
      experienceSettingsTab: "availability",
    })
  }

  function handleShareOpen(method: ShareMethod) {
    // The share link modal should only be shown on usage creation; When the copy link
    // button is clicked copy the link to clipboard instead
    if (method === "link") {
      copyToClipboard(shareUrl)
      return
    }
    setShareMethod(method)
    onOpen()
  }

  function getDefaultShareMethod(): ShareMethod | null {
    if (!openOnCreation || noShareReason) return null
    switch (contentUsage.entity) {
      case "organization":
        return "link"
      default:
        return "email"
    }
  }
}

export default observer(ShareContentUsageButton)
