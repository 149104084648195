import AddContentUsageModalExistingContentTab from "@/content-usage/buttons/add-button/AddContentUsageModalExistingContentTab"
import AddContentUsageModalNewContentTab from "@/content-usage/buttons/add-button/AddContentUsageModalNewContentTab"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { ContentUsageInput } from "@/content-usage/__generated__/ContentModulesDragDropProvider_UpdateContentUsageMutation.graphql"
import { ContentUsageEntity } from "@/content-usage/__generated__/ContentUsageUtils_UseParentEntityContentUsageFragment.graphql"
import GenerateContentWithAI from "@/content/ai/GenerateContentWithAI"
import { GenerateContentWithAISubmitProps } from "@/content/ai/GenerateContentWithAIForm"
import { ContentTemplateKind } from "@/content/util/contentTemplates"
import ContentUtils from "@/content/util/contentUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import AiStarsIcon from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AITag from "@components/ai/AITag"
import { DiscoIcon } from "@disco-ui"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { v4 as uuidv4 } from "uuid"

export type SelectedTab = "new" | "existing" | "generate"

interface AddContentUsageContentOptionsProps extends TestIDProps {
  onTemplateSelect: (template: ContentTemplateKind) => void
  onSelect?: VoidFunction
  addingTo: ContentUsageEntity
  productId?: GlobalID | null
  contentUsageInput?: ContentUsageInput | null
  moduleContentUsageId?: GlobalID
  onContentSelected?: (contentId: GlobalID) => void
  disabled?: boolean
  setSelectedTab: React.Dispatch<React.SetStateAction<SelectedTab>>
  selectedTab?: SelectedTab
  hideAIOption?: boolean
}

function AddContentUsageContentOptions({
  addingTo,
  onTemplateSelect,
  onSelect,
  productId,
  contentUsageInput,
  moduleContentUsageId,
  onContentSelected,
  disabled,
  setSelectedTab,
  selectedTab = "new",
  hideAIOption = false,
}: AddContentUsageContentOptionsProps) {
  const activeOrganization = useActiveOrganization()!
  const drawer = useContentUsageDrawer()
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <>
      <DiscoTabs
        routes={[
          {
            label: isMobile ? "New" : "New Content",
            onClick: () => {
              setSelectedTab("new")
            },
            testid: "new-tab",
            active: selectedTab === "new",
          },
          ...ArrayUtils.spreadIf(
            {
              label: isMobile ? "From Existing" : "From Existing Content",
              onClick: () => {
                setSelectedTab("existing")
              },
              testid: "existing-tab",
              active: selectedTab === "existing",
            },
            activeOrganization.viewerPermissions.has("content.manage")
          ),
          ...ArrayUtils.spreadIf(
            {
              label: "Generate",
              onClick: () => {
                setSelectedTab("generate")
              },
              testid: "generate",
              active: selectedTab === "generate",
              tabClassname:
                selectedTab === "generate" ? classes.activeGenerateTab : undefined,
              rightContent: selectedTab === "generate" && (
                <span className={classes.betaTag}>
                  <AITag name={"BETA"} />
                </span>
              ),
              leftIcon:
                selectedTab === "generate" ? (
                  <AiStarsIcon />
                ) : (
                  <DiscoIcon icon={"stars"} />
                ),
            },
            !hideAIOption
          ),
        ]}
        testid={"AddContentModal.tabs"}
        tabVariant={"default"}
      />
      {selectedTab === "new" ? (
        <AddContentUsageModalNewContentTab
          templatesToExclude={getTemplatesToExclude()}
          onTemplateSelect={onTemplateSelect}
          disabled={disabled}
        />
      ) : selectedTab === "existing" ? (
        <AddContentUsageModalExistingContentTab
          testid={"AddContentModal.existing-tab"}
          typesToExclude={ContentUtils.getTypesToExclude(addingTo)}
          productId={productId}
          contentUsageInput={contentUsageInput}
          onSelect={onSelect}
          addingTo={addingTo}
          onContentSelected={onContentSelected}
          disabled={disabled}
        />
      ) : selectedTab === "generate" ? (
        <GenerateContentWithAI
          onSubmit={onSubmitAiForm}
          moduleContentUsageId={moduleContentUsageId}
        />
      ) : null}
    </>
  )

  function getTemplatesToExclude(): ContentTemplateKind[] {
    const exclude: Array<ContentTemplateKind> = []
    if (addingTo !== "curriculum") {
      exclude.push(
        "assignment",
        "quiz",
        "connect_slack",
        "survey",
        "add_events_to_calendar",
        "complete_profile"
      )
    }

    if (hideAIOption) exclude.push("ai")
    return exclude
  }

  function onSubmitAiForm({
    prompt,
    referenceUrl,
    referenceEmbeddingSourceIds,
    referenceModuleContentUsageId,
  }: GenerateContentWithAISubmitProps) {
    drawer.open({
      drawerContentType: "text",
      drawerUsageEntity: contentUsageInput?.entity || undefined,
      drawerUsageEntityId: contentUsageInput?.entityId || undefined,
      contentTemplate: "ai",
      // Generate a new uuid for the ContentUsage so we do not have to navigate
      // to a new drawer after creation
      drawerContentUsageId: Relay.toGlobalId("ContentUsage", uuidv4()),
      drawerModuleContentUsageId: moduleContentUsageId || undefined,
      drawerAIPrompt: prompt,
      drawerAIReferenceUrl: referenceUrl,
      drawerAIReferenceEmbeddingSourceIds: encodeURIComponent(
        JSON.stringify(referenceEmbeddingSourceIds)
      ),
      drawerAIReferenceModuleContentUsageId: referenceModuleContentUsageId,
    })

    if (onSelect) onSelect()
  }
}
const useStyles = makeUseStyles((theme) => ({
  activeGenerateTab: {
    whiteSpace: "nowrap",
    "&:after": {
      position: "absolute",
      bottom: "0px",
      left: 0,
      width: "100%",
      height: "4px",
      borderRadius: `${theme.measure.borderRadius.default}px ${theme.measure.borderRadius.default}px 0 0`,
      backgroundColor: "transparent",
      backgroundImage: theme.palette.aiGradient.aiDark,
    },
    "& span": {
      color: "unset",
      backgroundImage: theme.palette.aiGradient.aiDark,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      MozBackgroundClip: "text",
      MozTextFillColor: "transparent",
    },
  },
  betaTag: {
    marginLeft: theme.spacing(1),
  },
}))

export default AddContentUsageContentOptions
