import ShareContentUsageForm from "@/content-usage/buttons/share/ShareContentUsageForm"
import { ShareContentUsageEmailModalBody_notificationAudienceQuery } from "@/content-usage/buttons/share/__generated__/ShareContentUsageEmailModalBody_notificationAudienceQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import {
  DiscoButtonSkeleton,
  DiscoFormControl,
  DiscoInputSkeleton,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { Grid, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"
import { graphql, useLazyLoadQuery } from "react-relay"
interface ShareContentUsageEmailModalBodyProps extends TestIDProps {
  onClose: VoidFunction
  contentUsageId: GlobalID
}

function ShareContentUsageEmailModalBody({
  onClose,
  contentUsageId,
}: ShareContentUsageEmailModalBodyProps) {
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const memberLabel = useLabel("product_member")

  const { notificationAudience } =
    useLazyLoadQuery<ShareContentUsageEmailModalBody_notificationAudienceQuery>(
      graphql`
        query ShareContentUsageEmailModalBody_notificationAudienceQuery(
          $organizationId: ID!
          $productId: ID!
        ) {
          notificationAudience(
            organizationId: $organizationId
            productId: $productId
            topic: "curriculum-item-share"
            notificationKindSlug: curriculum_item_share
            channel: email
          ) {
            totalCount
          }
        }
      `,
      {
        organizationId: activeOrganization?.id || "",
        productId: activeProduct?.id || "",
      }
    )
  const recipients = notificationAudience?.totalCount || 0

  return (
    <>
      <DiscoText marginBottom={2.5}>
        <DiscoText component={"span"} variant={"body-sm"} color={"text.secondary"}>
          {"Share with "}
        </DiscoText>
        <DiscoText component={"span"} variant={"body-sm-700"}>{`${pluralize(
          memberLabel.singular,
          recipients,
          true
        )}`}</DiscoText>
        {activeProduct?.name && (
          <>
            <DiscoText component={"span"} variant={"body-sm"} color={"text.secondary"}>
              {" in "}
            </DiscoText>
            <DiscoText
              component={"span"}
              variant={"body-sm-700"}
            >{`${activeProduct.name}.`}</DiscoText>
          </>
        )}
      </DiscoText>
      <ShareContentUsageForm contentUsageId={contentUsageId} onClose={onClose} />
    </>
  )
}

function ShareContentUsageEmailModalBodySkeleton() {
  const theme = useTheme()

  return (
    <>
      <DiscoTextSkeleton width={"80%"} variant={"body-sm"} marginBottom={2.5} />
      <DiscoTextSkeleton width={"60%"} variant={"body-sm"} marginBottom={2.5} />
      <DiscoFormControl label={"Email Subject"}>
        <DiscoInputSkeleton />
      </DiscoFormControl>
      <DiscoFormControl label={"Email Content"}>
        <Skeleton
          variant={"rect"}
          width={"100%"}
          height={"145px"}
          style={{
            borderRadius: theme.measure.borderRadius.medium,
            marginBottom: theme.spacing(6),
          }}
        />
      </DiscoFormControl>
      <Grid container justifyContent={"space-between"} wrap={"wrap"}>
        <DiscoButtonSkeleton width={"180px"} />
        <Grid
          item
          xs
          container
          justifyContent={"flex-end"}
          style={{ gap: theme.spacing(1) }}
        >
          <DiscoButtonSkeleton width={"120px"} />
          <DiscoButtonSkeleton width={"140px"} />
        </Grid>
      </Grid>
    </>
  )
}

export default Relay.withSkeleton({
  component: ShareContentUsageEmailModalBody,
  skeleton: ShareContentUsageEmailModalBodySkeleton,
})
