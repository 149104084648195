import Badge from "@/admin/experiences/badges/Badge"
import CommunityBadge from "@/community/CommunityBadge"
import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import { ContentUsageInstanceItemQuery } from "@/content-usage/__generated__/ContentUsageInstanceItemQuery.graphql"
import { ContentUsageInstanceItem_ContentUsageFragment$key } from "@/content-usage/__generated__/ContentUsageInstanceItem_ContentUsageFragment.graphql"
import { InlineContentDrawerFormStore } from "@/content-usage/drawer/InlineContentDrawerTemplate"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { ContentUsageDrawerSubtab } from "@/core/context/GlobalDrawerProvider"
import { useLabels } from "@/core/context/LabelsContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import ProductUtils from "@/product/util/productUtils"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoIcon, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

interface ItemProps extends TestIDProps {
  path?: string | null
  icon?: React.ReactNode | null
  title: string
  description: string
  navigateOnClick?: boolean
  variant: "tooltip" | "list"
  contentUsageId: string
  className?: string
  drawerTabOnItemNavigate?: ContentUsageDrawerSubtab
}

function Item(props: ItemProps) {
  const {
    path,
    icon,
    title,
    description,
    navigateOnClick,
    contentUsageId,
    variant,
    testid = "Item",
    drawerTabOnItemNavigate = "details",
    className: customClasses,
  } = props
  const { handleLeave } = useUnsavedChangesModalContext()

  const history = useHistory()
  const classes = useStyles({ variant })

  return (
    <DiscoContainerButton
      className={classNames(classes.item, customClasses)}
      onClick={handleNavigation}
      testid={testid}
    >
      {icon}
      <div className={classes.text}>
        <DiscoText variant={"body-sm-700"} truncateText={2}>
          {title}
        </DiscoText>
        <DiscoText variant={"body-sm"} truncateText={1}>
          {description}
        </DiscoText>
      </div>
    </DiscoContainerButton>
  )

  function handleNavigation(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!navigateOnClick || !path) return

    handleLeave({
      onLeave: () => {
        history.push({
          ...location,
          pathname: path,
          search: setSearchParams(location.search, {
            contentId: undefined,
            contentTemplate: undefined,
            u: Relay.fromGlobalId(contentUsageId).id,
            drawerTab: drawerTabOnItemNavigate,
          }),
        })
      },
    })

    // When in a tooltip we want to prevent the click from
    // bubbling up to any parent elements that may be listening
    e.stopPropagation()
  }
}

interface ItemPreviewProps extends TestIDProps {
  variant?: "tooltip" | "list"
  contentUsageForm: InlineContentDrawerFormStore
}

function ItemPreview({ contentUsageForm, variant = "list" }: ItemPreviewProps) {
  const labels = useLabels()

  const entityId = contentUsageForm.state.contentUsageInput?.entityId

  const { module } = useLazyLoadQuery<ContentUsageInstanceItemQuery>(
    graphql`
      query ContentUsageInstanceItemQuery($id: ID!) {
        module: node(id: $id) {
          ... on Content {
            id
            name
            product {
              name
              registrationAvailability
              badge {
                ...BadgeFragment
              }
            }
            usages {
              edges {
                node {
                  id
                  isDefaultCollectionModule
                  collection {
                    app {
                      customAppTitle
                      badge {
                        ...BadgeFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
        productApp: node(id: $id) {
          ... on ProductApp {
            id
            kind
          }
        }
      }
    `,
    {
      id: entityId || "",
    }
  )

  if (!contentUsageForm.state.contentUsageId) return null

  if (module) {
    if (module.product)
      return (
        <Item
          variant={variant}
          icon={
            module.product.badge && <Badge size={40} badgeKey={module.product.badge} />
          }
          title={module.product.name}
          description={`${ProductUtils.getAvailabilityLabel(
            module.product.registrationAvailability,
            labels.admin_member
          )} • ${module.name}`}
          contentUsageId={contentUsageForm.state.contentUsageId}
        />
      )

    const collectionModules = Relay.connectionToArray(module.usages)
    const collectionModule = collectionModules.length ? collectionModules[0] : null
    if (collectionModule?.collection) {
      const entity = module.name
      return (
        <Item
          variant={variant}
          icon={
            collectionModule.collection.app.badge && (
              <Badge size={40} badgeKey={collectionModule.collection.app.badge} />
            )
          }
          title={collectionModule.collection.app.customAppTitle || ""}
          description={collectionModule.isDefaultCollectionModule ? "" : entity || ""}
          contentUsageId={contentUsageForm.state.contentUsageId}
        />
      )
    }
  }

  return null
}

export function ContentUsageInstanceItemSkeleton(props: {
  variant?: "tooltip" | "list"
}) {
  const { variant = "list" } = props
  const classes = useStyles({ variant })

  return (
    <div className={classes.item}>
      <Skeleton className={classes.skeleton} variant={"circle"} width={40} height={40} />
      <div className={classes.text}>
        <DiscoTextSkeleton
          className={classes.skeleton}
          variant={"body-sm-700"}
          width={100}
        />
        <DiscoTextSkeleton className={classes.skeleton} variant={"body-sm"} width={200} />
      </div>
    </div>
  )
}

export const ContentUsageInstanceItemPreview = Relay.withSkeleton({
  component: ItemPreview,
  skeleton: () => <ContentUsageInstanceItemSkeleton />,
})

interface ContentUsageInstanceItemProps extends TestIDProps {
  navigateOnClick?: boolean
  variant?: "tooltip" | "list"
  contentUsageKey: ContentUsageInstanceItem_ContentUsageFragment$key
  className?: string
  drawerTabOnItemNavigate?: ContentUsageDrawerSubtab
}

function ContentUsageInstanceItem({
  contentUsageKey,
  navigateOnClick,
  variant = "list",
  drawerTabOnItemNavigate = "details",
  className,
}: ContentUsageInstanceItemProps) {
  const labels = useLabels()
  const activeOrganization = useActiveOrganization()!

  const contentUsage = useFragment<ContentUsageInstanceItem_ContentUsageFragment$key>(
    graphql`
      fragment ContentUsageInstanceItem_ContentUsageFragment on ContentUsage {
        id
        product {
          name
          registrationAvailability
          badge {
            ...BadgeFragment
          }
        }
        productApp {
          kind
          customAppTitle
          badge {
            ...BadgeFragment
          }
        }
        module {
          name
          usages {
            edges {
              node {
                id
                isDefaultCollectionModule
                collection {
                  app {
                    customAppTitle
                    badge {
                      ...BadgeFragment
                    }
                  }
                }
              }
            }
          }
        }
        entity
        ...ContentUsageUtils_useContentUsagePathContentUsageFragment
      }
    `,
    contentUsageKey
  )

  const classes = useStyles({ variant })
  const path = ContentUsageUtils.useContentUsagePath(contentUsage)

  // If content usage is within a product
  if (contentUsage.product) {
    // Content usage could be within a module or a product app
    const entity =
      contentUsage.module?.name ??
      contentUsage.productApp?.customAppTitle ??
      (contentUsage.entity === "dashboard_block_content" ? "Dashboard" : null)
    return (
      <Item
        testid={`ContentUsageInstanceItem.${contentUsage.product.name}`}
        path={path}
        variant={variant}
        icon={
          contentUsage.product.badge && (
            <Badge size={40} badgeKey={contentUsage.product.badge} />
          )
        }
        title={contentUsage.product.name}
        description={`${ProductUtils.getAvailabilityLabel(
          contentUsage.product.registrationAvailability,
          labels.admin_member
        )}${entity ? ` • ${entity}` : ""}`}
        navigateOnClick={navigateOnClick}
        contentUsageId={contentUsage.id}
        drawerTabOnItemNavigate={drawerTabOnItemNavigate}
        className={className}
      />
    )
  }

  if (contentUsage.entity === "dashboard_block_content") {
    return (
      <Item
        path={path}
        variant={variant}
        icon={
          <div className={classes.plainIcon}>
            <DiscoIcon icon={"house"} height={28} width={28} />
          </div>
        }
        title={"Community Home"}
        navigateOnClick={navigateOnClick}
        description={`All ${labels.organization_member.plural} • Dashboard`}
        contentUsageId={contentUsage.id}
      />
    )
  }

  // If content usage is used for a product app outside of a product
  if (contentUsage.productApp) {
    return (
      <Item
        path={path}
        testid={`ContentUsageInstanceItem.${contentUsage.productApp.customAppTitle}`}
        variant={variant}
        icon={
          contentUsage.productApp.badge && (
            <Badge size={40} badgeKey={contentUsage.productApp.badge} />
          )
        }
        title={contentUsage.productApp.customAppTitle || "Page"}
        navigateOnClick={navigateOnClick}
        description={`All ${labels.admin_member.plural}`}
        contentUsageId={contentUsage.id}
        drawerTabOnItemNavigate={drawerTabOnItemNavigate}
        className={className}
      />
    )
  }

  // Handling for community collection modules
  if (contentUsage.module) {
    const usage = Relay.connectionToArray(contentUsage.module.usages)[0]
    const entity = contentUsage.module?.name

    if (!usage.collection) return null

    const title = usage.collection.app.customAppTitle || ""
    return (
      <Item
        className={className}
        path={path}
        variant={variant}
        icon={
          usage.collection.app.badge && (
            <Badge size={40} badgeKey={usage.collection.app.badge} />
          )
        }
        title={title}
        description={usage.isDefaultCollectionModule ? "" : entity || ""}
        navigateOnClick={navigateOnClick}
        contentUsageId={contentUsage.id}
        drawerTabOnItemNavigate={drawerTabOnItemNavigate}
        testid={`ContentUsageInstanceItem.${title}`}
      />
    )
  }

  // Organization share link usage
  if (contentUsage.entity === "organization") {
    const title = "Community"
    return (
      <Item
        className={className}
        path={path}
        variant={variant}
        contentUsageId={contentUsage.id}
        navigateOnClick={navigateOnClick}
        drawerTabOnItemNavigate={drawerTabOnItemNavigate}
        testid={`ContentUsageInstanceItem.${title}`}
        icon={<CommunityBadge size={40} organizationKey={activeOrganization} />}
        title={title}
        description={`All ${labels.admin_member.plural} • Share Link`}
      />
    )
  }

  return null
}

type StyleProps = {
  variant: "tooltip" | "list"
}

const useStyles = makeUseStyles((theme) => ({
  item: ({ variant }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(0.75),
    borderRadius: theme.measure.borderRadius.default,

    "&:hover": {
      backgroundColor: theme.palette.groovy.neutral[200],
    },

    ...styleIf(variant === "tooltip", {
      "&:hover": {
        backgroundColor: theme.palette.groovy.neutral[500],
      },
    }),
  }),
  text: {
    display: "grid",
  },
  plainIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.groovy.neutral[100],
    width: "40px",
    height: "40px",
    borderRadius: theme.measure.borderRadius.default,
  },
  skeleton: ({ variant }: StyleProps) => ({
    ...styleIf(variant === "tooltip", {
      background: theme.palette.groovy.neutral[500],
    }),
  }),
}))

export default ContentUsageInstanceItem
