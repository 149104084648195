import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import { DeleteContentUsageButtonFragment$key } from "@/content-usage/buttons/__generated__/DeleteContentUsageButtonFragment.graphql"
import { DeleteContentUsageButtonMutation } from "@/content-usage/buttons/__generated__/DeleteContentUsageButtonMutation.graphql"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import ContentUsagePrerequisiteItem from "@/content-usage/modules/actions/ContentUsagePrerequisiteItem"
import UpdateContentModuleButton from "@/content-usage/modules/actions/UpdateContentModuleButton"
import ContentUtils from "@/content/util/contentUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoButtonProps, DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { useQueryParams } from "@utils/url/urlUtils"
import { useEffect } from "react"
import { useFragment, useMutation } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { graphql } from "relay-runtime"

interface DeleteContentUsageButtonProps extends DiscoButtonProps, TestIDProps {
  children: OverridableDiscoButtonChildren
  onDelete?: (deletedContentUsageId: string) => void
  contentUsageKey: DeleteContentUsageButtonFragment$key
}

function DeleteContentUsageButton({
  children,
  onDelete,
  contentUsageKey,
  testid,
  ...props
}: DeleteContentUsageButtonProps) {
  const activeOrganization = useActiveOrganization()!

  const contentUsageDrawer = useContentUsageDrawer()
  const curriculumLabel = useLabel("curriculum")
  const classes = useStyles()

  const contentUsage = useFragment<DeleteContentUsageButtonFragment$key>(
    graphql`
      fragment DeleteContentUsageButtonFragment on ContentUsage {
        id
        isLocked
        entity
        entityId
        productId
        dependents {
          edges {
            node {
              id
              content {
                isCurriculumModule
              }
              ...ContentUsagePrerequisiteItemFragment
              ...UpdateContentModuleButtonFragment
            }
          }
        }
        module {
          isCurriculumModule
        }
        content {
          type
          label
          isCurriculumModule
          allChildren: children {
            edges {
              node {
                id
              }
            }
          }
        }
        productApp {
          customAppTitle
        }
        ...ContentUsageUtils_useNavigateToNextContentUsageFragment
        ...ContentUsageUtils_UseParentEntityContentUsageFragment
      }
    `,
    contentUsageKey
  )

  const { parentEntity, isModule } = ContentUsageUtils.useParentEntity(contentUsage)
  const isCurriculumItem = parentEntity === "curriculum"
  const isCollectionItem = parentEntity === "collection"
  const navigateToNextItem = ContentUsageUtils.useNavigateToNextContentUsage(contentUsage)
  const { filterContentLabelId } = useQueryParams<{ filterContentLabelId?: string }>()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [commit, isDeleting] = useMutation<DeleteContentUsageButtonMutation>(graphql`
    mutation DeleteContentUsageButtonMutation(
      $id: ID!
      $contentLabelIds: [ID!]
      $isOrgCollectionFolder: Boolean!
      $isProductCollectionFolder: Boolean!
    ) {
      deleteContentUsage(id: $id) {
        deletedContentUsageId @deleteRecord
        deletedContentUsage {
          organization @include(if: $isOrgCollectionFolder) {
            dashboard {
              id
              mainBlocks: blocks(position: main) {
                edges {
                  node {
                    id
                    kind
                    ...DashboardBlockItemFragment
                  }
                }
              }
              sideBlocks: blocks(position: side) {
                edges {
                  node {
                    id
                    kind
                    ...DashboardBlockItemFragment
                  }
                }
              }
            }
          }
          product @include(if: $isProductCollectionFolder) {
            dashboard {
              id
              blocks(position: main) {
                edges {
                  node {
                    id
                    kind
                    ...DashboardBlockItemFragment
                  }
                }
              }
            }
          }
          ...ContentModuleUtils_RefreshContentModulesFragment
            @arguments(contentLabelIds: $contentLabelIds)
        }
        errors {
          field
          message
        }
      }
    }
  `)

  const childrenUsageIds = Relay.connectionToArray(contentUsage.content.allChildren).map(
    (child) => child.id
  )
  const isOpenInDrawer = [contentUsage.id, ...childrenUsageIds].includes(
    contentUsageDrawer.params.drawerContentUsageId
  )

  const contentLabel = ContentUtils.useContentLabel({
    content: contentUsage.content,
    entity: contentUsage.entity,
  })
  const contentLabelNoSpaces = contentLabel.replace(" ", "")
  const contentUsageDependents = Relay.connectionToArray(contentUsage.dependents)

  useEffect(() => {
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentUsageDrawer.isOpen])

  return (
    <>
      <OverridableDiscoButton onClick={onOpen} testid={testid} {...props}>
        {children}
      </OverridableDiscoButton>

      {contentUsageDependents.length === 0 ? (
        <DiscoWarningModal
          testid={`Delete${contentLabelNoSpaces}Button.delete-warning-modal`}
          onClose={onClose}
          isOpen={isOpen}
          confirmationButtonProps={{
            onClick: handleDeleteContent,
            shouldDisplaySpinner: isDeleting,
            children: "Yes, delete it",
          }}
          modalContentLabel={`Delete ${contentLabel} Button`}
          title={getTitle()}
          description={getDescription()}
        />
      ) : (
        <DiscoModal
          testid={`Delete${contentLabelNoSpaces}Button.delete-warning-modal`}
          onClose={onClose}
          isOpen={isOpen}
          body={renderBody()}
          modalContentLabel={`Delete ${contentLabel} Button`}
          title={getTitle()}
          subtitle={getDescription()}
          classes={{ body: classes.body }}
          buttons={
            <DiscoButton variant={"outlined"} color={"grey"} onClick={onClose}>
              {"Cancel"}
            </DiscoButton>
          }
        />
      )}
    </>
  )

  function getTitle() {
    if (contentUsageDependents.length > 0) {
      return "Prerequisite Content Detected"
    }

    if (parentEntity === "product_app") {
      return "Delete this app?"
    }

    return `Delete this ${contentLabel}?`
  }

  function renderBody() {
    if (contentUsageDependents.length < 1) return null

    return contentUsageDependents.map((cu) => (
      <ContentUsagePrerequisiteItem
        key={cu.id}
        contentModuleKey={cu}
        hideCheckbox
        disableLink={cu.content.isCurriculumModule}
        classes={{ selectedItem: classes.item }}
        rightContent={
          cu.content.isCurriculumModule && (
            <UpdateContentModuleButton contentUsageKey={cu}>
              {(btnProps) => (
                <DiscoButton
                  {...btnProps}
                  variant={"outlined"}
                  color={"grey"}
                  testid={`ContentUsageLockIcon.edit-module-button`}
                >
                  {"Edit Prerequisite Settings"}
                </DiscoButton>
              )}
            </UpdateContentModuleButton>
          )
        }
      />
    ))
  }

  function getDescription() {
    if (contentUsageDependents.length > 0) {
      return `This content cannot be deleted as it has been defined as a prerequisite for ${contentUsageDependents.length} other content. Please remove it as a prerequisite before proceeding with deletion`
    }
    if (isModule)
      return `Are you sure you want to permanently delete this ${contentLabel}? There is no way to undo this action.`

    switch (parentEntity) {
      case "curriculum":
        return `Are you sure you want to remove this ${contentLabel} from the ${curriculumLabel.singular}?`
      case "collection":
        return `Are you sure you want to remove this ${contentLabel} from the collection?`
      case "product_app":
        return `Are you sure you want to remove the ${contentUsage.productApp?.customAppTitle} app?`
      default:
        return `Are you sure you want to permanently delete this ${contentLabel}? There is no way to undo this action.`
    }
  }

  function handleDeleteContent() {
    commit({
      variables: {
        id: contentUsage.id,
        contentLabelIds: filterContentLabelId ? [filterContentLabelId] : undefined,
        isOrgCollectionFolder:
          contentUsage?.entity === "collection" && !contentUsage.productId,
        isProductCollectionFolder:
          contentUsage?.entity === "collection" && Boolean(contentUsage.productId),
      },
      onCompleted({ deleteContentUsage }) {
        if (deleteContentUsage.errors?.length)
          return displayErrorToast(deleteContentUsage.errors[0].message)
        if (onDelete) onDelete(contentUsage.id)

        onClose()

        displayToast({
          message: `${contentLabel} deleted!`,
          testid: `Delete${contentLabelNoSpaces}Button.success-toast`,
        })

        if (isOpenInDrawer) {
          if (isCurriculumItem || isCollectionItem) navigateToNextItem("replace")
          else contentUsageDrawer.close()
        }
      },
      optimisticUpdater: (store) => {
        const org = store.get(activeOrganization.id)
        if (!org) return

        ConnectionHandler.getConnections(
          org,
          "CommunityLibraryGrid__communityLibraryContentUsages"
        ).forEach((connection) => {
          ConnectionHandler.deleteNode(connection, contentUsage.id)
        })
      },
      updater: (store) => {
        if (isCollectionItem && !isModule)
          ContentModuleUtils.invalidateCollectionFolderConnections(
            store,
            contentUsage.entity,
            contentUsage.entityId
          )
      },
      onError(error) {
        displayErrorToast(error)
      },
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  item: {
    boxShadow: theme.palette.groovyDepths.insideCard,
    borderRadius: theme.measure.borderRadius.large,
    display: "flex",
    justifyContent: "space-between",
  },
  body: {
    paddingTop: theme.spacing(0.5),
  },
}))

export default DeleteContentUsageButton
