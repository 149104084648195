import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import { ContentUsageEntity } from "@/content-usage/buttons/share/__generated__/ShareContentUsageButtonFragment.graphql"
import { ShareContentUsageUtils_useShareUrlFragment$key } from "@/content-usage/buttons/share/__generated__/ShareContentUsageUtils_useShareUrlFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import useIsWaitingRoomEnabled from "@/product/util/hook/useIsWaitingRoomEnabled"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { useCallback } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export namespace ShareContentUsageUtils {
  /** Returns a callback to check a list of possible states that prevent sharing. Callback returns null when the item can be shared. */
  type CheckNotShareableArgs = {
    curriculumModuleId?: GlobalID
    usageEntity: ContentUsageEntity
  }
  export function useCheckNotShareable() {
    const curriculumModuleIds = ContentModuleUtils.useCurriculumModuleIds({
      releasedOnly: true,
    })
    const { hasActiveWaitingRoom } = useIsWaitingRoomEnabled()
    const activeProduct = useActiveProduct()
    return useCallback(
      ({ curriculumModuleId, usageEntity }: CheckNotShareableArgs) => {
        // can always share organization usages
        if (usageEntity === "organization") return null
        // can't share when the product isn't published
        if (activeProduct?.status !== "published") return "draft_product"
        // can't share while learners don't have access
        if (hasActiveWaitingRoom) return "waiting_room"
        // can't share if the module is unreleased
        if (!curriculumModuleIds.some((module) => module.moduleId === curriculumModuleId))
          return "unreleased_module"
        // there are no issues, the item can be shared
        return null
      },
      [activeProduct?.status, curriculumModuleIds, hasActiveWaitingRoom]
    )
  }

  export function useShareUrl(
    usageKey: ShareContentUsageUtils_useShareUrlFragment$key | null
  ): string {
    const activeOrganization = useActiveOrganization()!
    const usage = useFragment<ShareContentUsageUtils_useShareUrlFragment$key>(
      graphql`
        fragment ShareContentUsageUtils_useShareUrlFragment on ContentUsage {
          id
          entity
          ...ContentUsageUtils_useContentUsagePathContentUsageFragment
        }
      `,
      usageKey
    )
    const contentUsagePath = ContentUsageUtils.useContentUsagePath(usage)
    if (!usage) return ""

    // Override the content usage path for organization usages, since they normally open on the
    // current path, but when copying the share link it needs to have a globally-accessible path
    const path =
      usage.entity === "organization" ? ROUTE_NAMES.COMMUNITY.HOME.ROOT : contentUsagePath

    const url = new URL(`${activeOrganization.primaryDomain}${path}`)
    url.searchParams.set("u", Relay.fromGlobalId(usage.id).id)
    return url.toString()
  }
}
