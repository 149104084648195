/**
 * @generated SignedSource<<ce9daa942459797d1d7fdcb7a8bb87bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentUsageUtils_ContentCompletionFragment$data = {
  readonly id: string;
  readonly viewerHasCompleted: boolean;
  readonly dependents: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isLocked: boolean;
      };
    }>;
  };
  readonly module: {
    readonly id: string;
    readonly usages: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly dependents: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly isLocked: boolean;
                readonly content: {
                  readonly children: {
                    readonly edges: ReadonlyArray<{
                      readonly node: {
                        readonly id: string;
                        readonly isLocked: boolean;
                      };
                    }>;
                  } | null;
                };
              };
            }>;
          };
          readonly viewerHasCompleted: boolean;
          readonly completedProductMemberships: {
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly member: {
                  readonly id: string;
                  readonly first_name: string | null;
                  readonly last_name: string | null;
                  readonly avatar: string;
                };
              };
            }>;
          };
        };
      }>;
    };
    readonly viewerChildContentCompletions: {
      readonly totalCount: number;
    };
  } | null;
  readonly completedProductMemberships: {
    readonly totalCount: number;
  };
  readonly " $fragmentType": "ContentUsageUtils_ContentCompletionFragment";
};
export type ContentUsageUtils_ContentCompletionFragment$key = {
  readonly " $data"?: ContentUsageUtils_ContentCompletionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_ContentCompletionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasCompleted",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLocked",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContentUsageNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentUsage",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageUtils_ContentCompletionFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsageNodeConnection",
      "kind": "LinkedField",
      "name": "dependents",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "usages",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContentUsageNodeConnection",
                      "kind": "LinkedField",
                      "name": "dependents",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ContentUsageNodeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ContentUsage",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Content",
                                  "kind": "LinkedField",
                                  "name": "content",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "ContentUsageNodeConnection",
                                      "kind": "LinkedField",
                                      "name": "children",
                                      "plural": false,
                                      "selections": (v3/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductMembershipNodeConnection",
                      "kind": "LinkedField",
                      "name": "completedProductMemberships",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductMembershipNodeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProductMembership",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "User",
                                  "kind": "LinkedField",
                                  "name": "member",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "alias": "first_name",
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "firstName",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": "last_name",
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "lastName",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "avatar",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentCompletionNodeConnection",
          "kind": "LinkedField",
          "name": "viewerChildContentCompletions",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "completedProductMemberships",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "968fa358fc1e74a5012f4f60e43fb090";

export default node;
