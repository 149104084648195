import ContentTemplateLists from "@/content/templates/ContentTemplateLists"
import { ContentTemplateKind } from "@/content/util/contentTemplates"
import makeUseStyles from "@assets/style/util/makeUseStyles"

type Props = {
  templatesToExclude: ContentTemplateKind[]
  onTemplateSelect: (template: ContentTemplateKind) => void
  disabled?: boolean
}

function AddContentUsageModalNewContentTab({
  templatesToExclude,
  onTemplateSelect,
  disabled,
}: Props) {
  const classes = useStyles()

  return (
    <ContentTemplateLists
      onTemplateSelect={onTemplateSelect}
      className={classes.templateList}
      templatesToExclude={templatesToExclude}
      disabled={disabled}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  templateList: {
    marginTop: theme.spacing(1.5),
  },
}))

export default AddContentUsageModalNewContentTab
