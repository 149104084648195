import { ShareContentUsageFormMutation } from "@/content-usage/buttons/share/__generated__/ShareContentUsageFormMutation.graphql"
import { ShareContentUsageForm_PreviewMutation } from "@/content-usage/buttons/share/__generated__/ShareContentUsageForm_PreviewMutation.graphql"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoFormControl, DiscoInput } from "@disco-ui"
import { Grid, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql } from "relay-runtime"

interface ShareContentUsageFormProps extends TestIDProps {
  contentUsageId: GlobalID
  onClose: VoidFunction
}

function ShareContentUsageForm({
  contentUsageId,
  onClose,
  testid = "ShareContentUsageForm",
}: ShareContentUsageFormProps) {
  const { authUser } = useAuthUser({ required: true })
  const theme = useTheme()

  const form = useFormStore<ShareContentUsageFormMutation>(
    graphql`
      mutation ShareContentUsageFormMutation($input: ShareContentUsageInput!) {
        response: shareContentUsage(input: $input) {
          errors {
            field
            message
          }
        }
      }
    `,
    {
      contentUsageId,
      body: JSON.stringify(
        EditorUtils.createParagraphs([
          "Hi {{ user.firstName }} {{ user.lastName }}, ",
          "There's new content available in {{ product.name }}!",
          "To check it out, click the button below:",
        ])
      ),
      subject: "New content is available in {{ product.name }}",
    },
    { requireChangeToSubmit: false }
  )

  const previewEmailForm = useFormStore<ShareContentUsageForm_PreviewMutation>(
    graphql`
      mutation ShareContentUsageForm_PreviewMutation(
        $input: PreviewShareContentUsageEmailInput!
      ) {
        response: previewShareContentUsageEmail(input: $input) {
          data
          errors {
            field
            message
          }
        }
      }
    `,
    {
      // this form data is never updated, using this store for built-in error handling, when this form is submitted we use form data from the form defined above
      subject: "",
      body: "",
      contentUsageId: "",
    },
    { requireChangeToSubmit: false }
  )

  return (
    <Form
      id={"ShareContentUsageForm"}
      onSubmit={handleSubmit}
      buttons={
        <Grid
          container
          justifyContent={"space-between"}
          style={{ gap: theme.spacing(0.5) }}
        >
          <Grid item>
            <DiscoButton
              testid={`${testid}.preview-button`}
              color={"transparent"}
              onClick={handlePreviewSubmit}
              disabled={!form.state.subject || EditorUtils.isEmpty(form.state.body)}
              leftIcon={"send"}
            >
              {"Send Me Preview"}
            </DiscoButton>
          </Grid>
          <Grid
            container
            item
            xs
            justifyContent={"flex-end"}
            style={{ gap: theme.spacing(1) }}
          >
            <DiscoButton
              color={"grey"}
              variant={"outlined"}
              onClick={onClose}
              testid={`${testid}.cancel`}
            >
              {"No, Thanks"}
            </DiscoButton>
            <Form.SubmitButton
              form={form}
              id={"ShareContentUsageForm"}
              testid={`${testid}.submit-button`}
              disabled={!form.state.subject || EditorUtils.isEmpty(form.state.body)}
            >
              {"Share"}
            </Form.SubmitButton>
          </Grid>
        </Grid>
      }
    >
      <DiscoFormControl
        error={!!form.errorsByField.subject?.length}
        errorMessages={form.errorsByField.subject}
        label={"Email Subject"}
      >
        <DiscoInput
          fullWidth
          value={form.state.subject}
          onChange={(e) => (form.state.subject = e.target.value)}
          placeholder={"Add a subject"}
          inputProps={{ "data-testid": `${testid}.subject` }}
        />
      </DiscoFormControl>
      <DiscoFormControl
        label={"Email Content"}
        error={!!form.errorsByField.body?.length}
        errorMessages={form.errorsByField.body}
      >
        <DiscoEditor
          defaultValue={form.state.body}
          onChange={(v) => (form.state.body = v)}
          config={"email"}
          testid={`${testid}.rich-text-body`}
          showOutline
        />
      </DiscoFormControl>
    </Form>
  )
  async function handleSubmit() {
    const { didSave } = await form.submit(form.state)
    if (!didSave) return
    displaySuccessToast({
      message: "Content successfully shared!",
      testid: `${testid}.success-toast`,
    })
    onClose()
  }

  async function handlePreviewSubmit() {
    const { didSave } = await previewEmailForm.submit({
      contentUsageId,
      body: form.state.body,
      subject: form.state.subject,
    })
    if (!didSave) return
    displaySuccessToast({
      message: `Preview email sent to ${authUser.email}`,
      testid: `${testid}.preview-email.success-toast`,
    })
  }
}

export default observer(ShareContentUsageForm)
