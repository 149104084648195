import { ContentUsageInput } from "@/content-usage/__generated__/ContentModulesDragDropProvider_UpdateContentUsageMutation.graphql"
import { ContentUsageEntity } from "@/content-usage/__generated__/ContentUsageUtils_UseParentEntityContentUsageFragment.graphql"
import AddContentUsageContentListItem, {
  AddContentUsageContentListItemSkeleton,
} from "@/content-usage/buttons/add-button/AddContentUsageContentListItem"
import { ExistingContentListPaginationQuery } from "@/content-usage/buttons/add-button/__generated__/ExistingContentListPaginationQuery.graphql"
import { ExistingContentList_PaginationFragment$key } from "@/content-usage/buttons/add-button/__generated__/ExistingContentList_PaginationFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoText } from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { TestIDProps } from "@utils/typeUtils"
import { usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

export const EXISTING_CONTENT_CONTENTS_PER_LOAD = 15
interface Props extends TestIDProps {
  onSelect?: VoidFunction
  organizationKey: ExistingContentList_PaginationFragment$key
  addingTo: ContentUsageEntity
  productId?: GlobalID | null
  contentUsageInput?: ContentUsageInput | null
  onContentSelected?: (contentId: GlobalID) => void
  disabled?: boolean
}

function ExistingContentList({
  testid = "ExistingContentList",
  productId,
  contentUsageInput,
  onContentSelected,
  addingTo,
  onSelect,
  organizationKey,
  disabled,
}: Props) {
  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    ExistingContentListPaginationQuery,
    ExistingContentList_PaginationFragment$key
  >(
    graphql`
      fragment ExistingContentList_PaginationFragment on Organization
      @refetchable(queryName: "ExistingContentListPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int!" }
        after: { type: "String" }
        search: { type: "String" }
        contentLabelIds: { type: "[ID!]" }
        excludedTypes: { type: "[ContentType!]" }
        productIds: { type: "[ID!]" }
        collectionIds: { type: "[ID!]" }
      ) {
        adminLibraryContents(
          first: $first
          after: $after
          search: $search
          contentLabelIds: $contentLabelIds
          excludedTypes: $excludedTypes
          productIds: $productIds
          collectionIds: $collectionIds
        ) @connection(key: "ExistingContentList__adminLibraryContents") {
          edges {
            node {
              id
              ...AddContentUsageContentListItemFragment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organizationKey
  )

  const adminLibraryContents = Relay.connectionToArray(data?.adminLibraryContents)

  if (!adminLibraryContents.length)
    return (
      <div data-test-id={`${testid}.no-results`}>
        <DiscoText
          variant={"body-lg-600"}
          align={"center"}
          marginTop={3}
          marginBottom={1}
        >
          {"No results found"}
        </DiscoText>
        <DiscoText color={"text.secondary"} align={"center"} marginBottom={6}>
          {"Try changing your search or filters"}
        </DiscoText>
      </div>
    )

  return (
    <>
      {adminLibraryContents.map((c) => {
        return (
          <AddContentUsageContentListItem
            key={c.id}
            testid={"ExistingContentList"}
            contentKey={c}
            productId={productId}
            contentUsageInput={contentUsageInput}
            onSelect={onSelect}
            addingTo={addingTo}
            onContentSelected={onContentSelected}
            disabled={disabled}
          />
        )
      })}

      {hasNext && (
        <DiscoScrolledIntoView
          isLoading={isLoadingNext}
          onScrolledIntoView={() => loadNext(EXISTING_CONTENT_CONTENTS_PER_LOAD)}
        />
      )}
    </>
  )
}

export function ExistingContentListSkeleton() {
  return (
    <>
      <AddContentUsageContentListItemSkeleton />
      <AddContentUsageContentListItemSkeleton />
      <AddContentUsageContentListItemSkeleton />
      <AddContentUsageContentListItemSkeleton />
      <AddContentUsageContentListItemSkeleton />
      <AddContentUsageContentListItemSkeleton />
    </>
  )
}

export default Relay.withSkeleton({
  component: ExistingContentList,
  skeleton: ExistingContentListSkeleton,
})
