/**
 * @generated SignedSource<<2a8e7f2908327d9c0af79d435efc9abd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AddContentUsageContentListItemFragment$data = {
  readonly id: string;
  readonly type: ContentType;
  readonly name: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ContentThumbnailWithDetails_ContentFragment">;
  readonly " $fragmentType": "AddContentUsageContentListItemFragment";
};
export type AddContentUsageContentListItemFragment$key = {
  readonly " $data"?: AddContentUsageContentListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddContentUsageContentListItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddContentUsageContentListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentThumbnailWithDetails_ContentFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "99780842e47dedc98f5ba88b940ed6d8";

export default node;
