/**
 * @generated SignedSource<<4971ed411e504815bdedc3ca2418dcc5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentModuleUtils_RefreshContentModulesFragment$data = {
  readonly id: string;
  readonly curriculum: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ContentModuleUtils_RefreshCurriculumFragment">;
  } | null;
  readonly collection: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ContentModuleUtils_RefreshCollectionFragment">;
  } | null;
  readonly " $fragmentType": "ContentModuleUtils_RefreshContentModulesFragment";
};
export type ContentModuleUtils_RefreshContentModulesFragment$key = {
  readonly " $data"?: ContentModuleUtils_RefreshContentModulesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentModuleUtils_RefreshContentModulesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contentLabelIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentModuleUtils_RefreshContentModulesFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Curriculum",
      "kind": "LinkedField",
      "name": "curriculum",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": [
            {
              "kind": "Variable",
              "name": "contentLabelIds",
              "variableName": "contentLabelIds"
            }
          ],
          "kind": "FragmentSpread",
          "name": "ContentModuleUtils_RefreshCurriculumFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Collection",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentModuleUtils_RefreshCollectionFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "8956c7ffb744d6c49827ad8b73cd4710";

export default node;
