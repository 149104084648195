import React, { useCallback, useState } from "react"
import useOnWindowResize from "../../utils/hook/useOnWindowResize"

const initialWindowSizeState = {
  currentSize: window.innerWidth,
  currentHeight: window.innerHeight,
}

const WindowSizeContext = React.createContext(initialWindowSizeState)

const WindowSizeProvider: React.FC = ({ children }) => {
  const [windowSize, setWindowSize] = useState(initialWindowSizeState)

  useOnWindowResize(
    useCallback(() => {
      setWindowSize({
        currentSize: window.innerWidth,
        currentHeight: window.innerHeight,
      })
    }, [])
  )

  return (
    <WindowSizeContext.Provider value={windowSize}>{children}</WindowSizeContext.Provider>
  )
}

export { WindowSizeProvider, WindowSizeContext }
