import { useTrackContentCompletionFragment$key } from "@/content-usage/buttons/__generated__/useTrackContentCompletionFragment.graphql"
import { useTrackContentCompletionMutation } from "@/content-usage/buttons/__generated__/useTrackContentCompletionMutation.graphql"
import { useContentUsageDrawerNavigation } from "@/content-usage/drawer/footer/useContentUsageDrawerNavigation"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import ContentUtils from "@/content/util/contentUtils"
import { getNextCurriculumItem } from "@/content/util/curriculumUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { useCallback } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

function useTrackContentCompletion(
  contentUsageKey: useTrackContentCompletionFragment$key
) {
  const activeProduct = useActiveProduct()
  const activeOrganization = useActiveOrganization()!
  const contentUsageDrawer = useContentUsageDrawer()
  const canManage = activeProduct?.viewerPermissions.has("content.manage")

  const { handleNav } = useContentUsageDrawerNavigation()

  const contentUsage = useFragment<useTrackContentCompletionFragment$key>(
    graphql`
      fragment useTrackContentCompletionFragment on ContentUsage {
        id
        entity
        content {
          type
          label
        }
      }
    `,
    contentUsageKey
  )

  const contentLabel = ContentUtils.useContentLabel({
    content: contentUsage.content,
    entity: contentUsage.entity,
  })
  const form = useFormStore<useTrackContentCompletionMutation>(
    graphql`
      mutation useTrackContentCompletionMutation($input: ContentCompletionInput!) {
        response: completeContent(input: $input) {
          node {
            id
            contentUsage {
              ...ContentUsageUtils_ContentCompletionFragment
              product {
                curriculum {
                  ...curriculumUtils__getNextCurriculumItem
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      contentUsageId: contentUsage.id,
      membershipId:
        activeProduct?.viewerMembership?.id || activeOrganization.viewerMembership!.id,
    }
  )

  return useCallback(async () => {
    const { didSave, response } = await form.submit(form.state)

    if (!didSave || !response?.node?.contentUsage) return

    displaySuccessToast({
      message: `${contentLabel} completed!`,
      testid: "useTrackContentCompletion.success-toast",
    })

    if (!response.node.contentUsage.product?.curriculum) return
    const nextUsage = getNextCurriculumItem(
      contentUsageDrawer.params.drawerContentUsageId,
      response.node.contentUsage.product?.curriculum,
      canManage
    )

    if (!nextUsage) return
    handleNav(nextUsage)
  }, [
    form,
    contentLabel,
    handleNav,
    canManage,
    contentUsageDrawer.params.drawerContentUsageId,
  ])
}

export default useTrackContentCompletion
