/**
 * @generated SignedSource<<8b97f6b46ffe62f276f56e5a48022c1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "content_usage_confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShareContentUsageButtonFragment$data = {
  readonly id: string;
  readonly entity: ContentUsageEntity;
  readonly module: {
    readonly id: string;
  } | null;
  readonly content: {
    readonly label: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ShareContentUsageUtils_useShareUrlFragment">;
  readonly " $fragmentType": "ShareContentUsageButtonFragment";
};
export type ShareContentUsageButtonFragment$key = {
  readonly " $data"?: ShareContentUsageButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShareContentUsageButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShareContentUsageButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShareContentUsageUtils_useShareUrlFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "806f35c7f71a695ceb351796f0f2aa9e";

export default node;
