import ShareContentUsageLinkModalBody from "@/content-usage/buttons/share/ShareContentUsageLinkModalBody"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoModal } from "@disco-ui"

type Props = {
  contentUsageId: GlobalID
  isOpen: boolean
  onClose: () => void
  title: string
}

export default function ShareContentUsageLinkModal(props: Props) {
  const { contentUsageId, isOpen, onClose, title } = props
  const classes = useStyles()

  return (
    <DiscoModal
      isOpen={isOpen}
      onClose={onClose}
      testid={"ShareContentUsageLinkModal"}
      classes={{ headerRow: classes.header }}
      title={title}
      modalContentLabel={title}
      body={
        isOpen && (
          <ShareContentUsageLinkModalBody
            contentUsageId={contentUsageId}
            onClose={onClose}
          />
        )
      }
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    padding: theme.spacing(2, 3, 1.5),
  },
}))
