/**
 * @generated SignedSource<<a5f705951ab19a40a116a1e8b23a3d47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "content_usage_confirmation" | "%future added value";
export type ProductAppKind = "content" | "link" | "curriculum" | "events" | "members" | "resources" | "slack" | "collection" | "product_link" | "organization_events" | "organization_members" | "chat_channel" | "posts" | "nav_folder" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment$data = {
  readonly id: string;
  readonly entity: ContentUsageEntity;
  readonly entityId: string;
  readonly product: {
    readonly name: string;
  } | null;
  readonly productApp: {
    readonly kind: ProductAppKind;
    readonly customAppTitle: string | null;
  } | null;
  readonly collection: {
    readonly app: {
      readonly customAppTitle: string | null;
    };
  } | null;
  readonly module: {
    readonly name: string | null;
  } | null;
  readonly " $fragmentType": "ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment";
};
export type ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment$key = {
  readonly " $data"?: ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customAppTitle",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "productApp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Collection",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductApp",
          "kind": "LinkedField",
          "name": "app",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "8e7358a52dffcd2842b4474c44c49143";

export default node;
