/**
 * @generated SignedSource<<c8cbc9e44dda484ba6fb471ce7169e12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "content_usage_confirmation" | "%future added value";
export type ShareContentUsageLinkModalBodyQuery$variables = {
  contentUsageId: string;
};
export type ShareContentUsageLinkModalBodyQuery$data = {
  readonly usage: {
    readonly entity?: ContentUsageEntity;
    readonly content?: {
      readonly label: string;
      readonly type: ContentType;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ShareContentUsageUtils_useShareUrlFragment">;
  } | null;
};
export type ShareContentUsageLinkModalBodyQuery = {
  variables: ShareContentUsageLinkModalBodyQuery$variables;
  response: ShareContentUsageLinkModalBodyQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contentUsageId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "contentUsageId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entity",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShareContentUsageLinkModalBodyQuery",
    "selections": [
      {
        "alias": "usage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ShareContentUsageUtils_useShareUrlFragment"
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShareContentUsageLinkModalBodyQuery",
    "selections": [
      {
        "alias": "usage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductApp",
                "kind": "LinkedField",
                "name": "productApp",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "module",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentUsageNodeConnection",
                    "kind": "LinkedField",
                    "name": "usages",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentUsageNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentUsage",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b83e34032f918165aaf5ccdf0ced44d8",
    "id": null,
    "metadata": {},
    "name": "ShareContentUsageLinkModalBodyQuery",
    "operationKind": "query",
    "text": "query ShareContentUsageLinkModalBodyQuery(\n  $contentUsageId: ID!\n) {\n  usage: node(id: $contentUsageId) {\n    __typename\n    ... on ContentUsage {\n      entity\n      content {\n        label\n        type\n        id\n      }\n      ...ShareContentUsageUtils_useShareUrlFragment\n    }\n    id\n  }\n}\n\nfragment ContentUsageUtils_useContentUsagePathContentUsageFragment on ContentUsage {\n  id\n  entity\n  entityId\n  product {\n    slug\n    id\n  }\n  productApp {\n    kind\n    id\n  }\n  module {\n    usages {\n      edges {\n        node {\n          id\n          entity\n          entityId\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ShareContentUsageUtils_useShareUrlFragment on ContentUsage {\n  id\n  entity\n  ...ContentUsageUtils_useContentUsagePathContentUsageFragment\n}\n"
  }
};
})();

(node as any).hash = "b6731bb7c39b80e1a5007e3310f2359c";

export default node;
