import { ShareContentUsageUtils } from "@/content-usage/buttons/share/ShareContentUsageUtils"
import { ShareContentUsageLinkModalBodyQuery } from "@/content-usage/buttons/share/__generated__/ShareContentUsageLinkModalBodyQuery.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoFormControl,
  DiscoIcon,
  DiscoIconButton,
  DiscoInput,
  DiscoInputSkeleton,
  DiscoLink,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { Grid, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useLazyLoadQuery } from "react-relay"

type Props = TestIDProps & {
  contentUsageId: GlobalID
  onClose: () => void
}

function ShareContentUsageLinkModalBody(props: Props) {
  const { testid = "ShareContentUsageLinkModalBody", onClose, contentUsageId } = props
  const copyToClipboard = useCopyToClipboard()
  const memberLabel = useLabel("admin_member")
  const classes = useStyles()

  const { usage } = useLazyLoadQuery<ShareContentUsageLinkModalBodyQuery>(
    graphql`
      query ShareContentUsageLinkModalBodyQuery($contentUsageId: ID!) {
        usage: node(id: $contentUsageId) {
          ... on ContentUsage {
            entity
            content {
              label
              type
            }
            ...ShareContentUsageUtils_useShareUrlFragment
          }
        }
      }
    `,
    { contentUsageId }
  )
  const url = ShareContentUsageUtils.useShareUrl(usage)
  if (!usage) return null

  const contentLabel = usage.content?.label || "content"

  return (
    <>
      <DiscoFormControl
        label={<DiscoText variant={"body-md-600"}>{"Copy Link"}</DiscoText>}
        sublabel={
          usage.entity === "organization"
            ? `Any ${memberLabel.singular} with the link will have access to this ${contentLabel}.`
            : `Sharing this link will go directly to this instance of the ${contentLabel}.`
        }
        marginBottom={0}
      >
        <DiscoInput
          data-testid={`${testid}.link-input`}
          fullWidth
          readOnly
          value={url}
          endAdornment={
            <DiscoIconButton
              testid={`${testid}.copy-link`}
              onClick={() => copyToClipboard(url.toString())}
              size={"small"}
            >
              <DiscoIcon icon={"copy"} />
            </DiscoIconButton>
          }
        />
      </DiscoFormControl>
      {usage.content?.type === "survey" && (
        <DiscoText variant={"body-sm"} color={"text.secondary"} marginTop={3}>
          {"You can find your surveys in "}
          <DiscoLink
            to={ROUTE_NAMES.ADMIN.CONTENT.SURVEYS.ROOT}
            textVariant={"body-sm-500"}
          >
            {"Admin > Content > Surveys"}
          </DiscoLink>
          {"."}
        </DiscoText>
      )}
      <div className={classes.buttons}>
        <DiscoButton testid={`${testid}.close`} onClick={onClose}>
          {"Thanks, All Done!"}
        </DiscoButton>
      </div>
    </>
  )
}

function ShareContentUsageLinkModalBodySkeleton() {
  const theme = useTheme()

  return (
    <>
      <DiscoTextSkeleton width={"80%"} variant={"body-sm"} marginBottom={2.5} />
      <DiscoTextSkeleton width={"60%"} variant={"body-sm"} marginBottom={2.5} />
      <DiscoFormControl label={"Email Subject"}>
        <DiscoInputSkeleton />
      </DiscoFormControl>
      <DiscoFormControl label={"Email Content"}>
        <Skeleton
          variant={"rect"}
          width={"100%"}
          height={"145px"}
          style={{
            borderRadius: theme.measure.borderRadius.medium,
            marginBottom: theme.spacing(6),
          }}
        />
      </DiscoFormControl>
      <Grid container justifyContent={"space-between"} wrap={"wrap"}>
        <DiscoButtonSkeleton width={"180px"} />
        <Grid
          item
          xs
          container
          justifyContent={"flex-end"}
          style={{ gap: theme.spacing(1) }}
        >
          <DiscoButtonSkeleton width={"120px"} />
          <DiscoButtonSkeleton width={"140px"} />
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  buttons: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
  },
}))

export default Relay.withSkeleton({
  component: ShareContentUsageLinkModalBody,
  skeleton: ShareContentUsageLinkModalBodySkeleton,
})
